import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createSignalRContext } from 'react-signalr';
import { ProviderProps } from 'react-signalr/lib/signalr/provider';
import { FCWithChildren } from '../../types/FCWithChildren';
import { ProcessesCallbacksNames, ProcessesHub, ProcessesMethodNames } from '../../hubs/ProcessesHub';
import { v4 as uuid } from 'uuid';
import { useCurrentUser } from '../../global-state/Auth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Obj = Record<string, any>;

type Actions = {
  listenToProcess: <TSuccess extends Obj>(listenToId: string, timeoutSeconds?: number) => Promise<TSuccess>;
};

export const SignalRProcessesContext = createContext<Actions>(null!);

export const useProcessesHub = (): Actions => useContext(SignalRProcessesContext);

export const SignalRProcessesProvider: FCWithChildren<ProviderProps> = (props) => {
  const { children, ...rest } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listeners = useRef<Record<string, { timeoutId: string; resolve: (value: any) => void; reject: () => void }[]>>({});

  const currentUser = useCurrentUser((x) => x.value);

  const signalRContext = useMemo(
    () =>
      createSignalRContext<ProcessesHub>({
        shareConnectionBetweenTab: false,
      }),
    [],
  );

  const [shouldConnect, setShouldConnect] = useState(false);

  useEffect(() => {
    if (currentUser) setShouldConnect(true);
  }, [currentUser]);

  const listenToProcess = useCallback(
    <TSuccess extends Obj>(listenToId: string, timeoutSeconds = -1) => {
      const promise = new Promise<TSuccess>((resolve, reject) => {
        const timeoutId = uuid();

        if (!listeners.current[listenToId]) listeners.current[listenToId] = [];
        listeners.current[listenToId].push({ timeoutId, resolve, reject });

        if (timeoutSeconds > 0) {
          setTimeout(() => {
            listeners.current[listenToId] = listeners.current[listenToId].filter((x) => x.timeoutId !== timeoutId);

            signalRContext.connection?.invoke(ProcessesMethodNames.UnsubscribeFromProcess, listenToId);
            reject('timeout waiting for process ' + listenToId);
          }, timeoutSeconds * 1000);
        }
      });

      signalRContext.connection?.invoke(ProcessesMethodNames.SubscribeToProcess, listenToId);

      return promise;
    },
    [signalRContext.connection],
  );

  signalRContext.useSignalREffect(
    ProcessesCallbacksNames.Success,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (id: string, data: any) => {
      if (!listeners.current[id]?.length) {
        return;
      }

      signalRContext.connection?.invoke(ProcessesMethodNames.UnsubscribeFromProcess, id);
      const toResolve = listeners.current[id];
      listeners.current[id] = [];
      for (const listener of toResolve) {
        listener.resolve(data);
      }
    },
    [],
  );

  signalRContext.useSignalREffect(
    ProcessesCallbacksNames.Failure,
    (id: string) => {
      if (!listeners.current[id]?.length) {
        return;
      }

      signalRContext.connection?.invoke(ProcessesMethodNames.UnsubscribeFromProcess, id);
      const toResolve = listeners.current[id];
      listeners.current[id] = [];
      for (const listener of toResolve) {
        listener.reject();
      }
    },
    [],
  );

  const contextValues = useMemo(
    () => ({
      listenToProcess,
    }),
    [listenToProcess],
  );

  if (!currentUser) {
    return <>{children}</>;
  }

  return (
    <SignalRProcessesContext.Provider value={contextValues}>
      <signalRContext.Provider connectEnabled={shouldConnect} {...rest}>
        {children}
      </signalRContext.Provider>
    </SignalRProcessesContext.Provider>
  );
};
