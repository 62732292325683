import { useMemo } from 'react';
import { SupportedLanguage, isSupportedLanguage, systemDefaultLanguageCode } from '../types/Languages';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

function useFallbackLanguage(): SupportedLanguage {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { i18n } = useTranslation();

  return useMemo(() => {
    const queryLang = query.get('lang');
    const browserLang = navigator.language.slice(0, 2);
    if (queryLang && isSupportedLanguage(queryLang)) {
      return queryLang as SupportedLanguage;
    } else if (isSupportedLanguage(browserLang)) {
      return browserLang as SupportedLanguage;
    } else {
      return i18n.language ?? systemDefaultLanguageCode;
    }
  }, [i18n.language, query]);
}

export default useFallbackLanguage;
