import { useCallback } from 'react';
import User from '../../models/User';
import { useCurrentUser } from '../../global-state/Auth';
import { useCurrentClient } from '../../global-state/Clients';

export const hasPermission = (clientId: string | undefined, currentUser: User | null, permissions: string | string[]) => {
  const userRoles = clientId ? currentUser?.roles[clientId] || [] : currentUser?.roles[currentUser.defaultAccountId || ''] || [];

  if (permissions) {
    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }

    return permissions.some((role) => {
      return userRoles.indexOf(role) >= 0;
    });
  }
  return false;
};

const usePermissions = () => {
  const currentUser = useCurrentUser((x) => x.value);
  const currentClient = useCurrentClient((x) => x.value);

  return useCallback(
    (permissions: string | string[], clientIdOverride?: string) => {
      return hasPermission(clientIdOverride ?? currentClient?.id, currentUser, permissions);
    },
    [currentClient?.id, currentUser],
  );
};

export default usePermissions;
