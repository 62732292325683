import { DateInterval, DateIntervalKeys } from './../models/DateInterval';
import { Namespace, TFunction } from 'i18next';

class DateUtils {
  static months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'] as const;

  static getDaysBetween(dateFrom: Date, dateTo: Date = new Date()): number {
    return Math.round((dateFrom.getTime() - dateTo.getTime()) / (1000 * 60 * 60 * 24));
  }

  static formatDayLongMonth(date: Date, locale?: string): string {
    return Intl.DateTimeFormat(locale, {
      day: 'numeric', // Show day of the month
      month: 'long', // Show full month name
    }).format(date);
  }

  // NOTE: locale should include language AND country, eg: en-ZA or de-DE
  static formatDate(date: Date, locale?: string): string {
    return Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(date);
  }

  // NOTE: locale should include language AND country, eg: en-ZA or de-DE
  static formatTime(date: Date, showSeconds = false, locale?: string): string {
    return Intl.DateTimeFormat(locale, { timeStyle: showSeconds ? 'medium' : 'short' }).format(date);
  }

  // NOTE: locale should include language AND country, eg: en-ZA or de-DE
  static formatDateTime(date: Date, showSeconds = false, hour24?: boolean, locale?: string): string {
    // not calling formatDate & formatTime because the seperator isn't always a space
    return Intl.DateTimeFormat(locale, { dateStyle: 'short', timeStyle: showSeconds ? 'medium' : 'short', hour12: !hour24 }).format(date);
  }

  static formatDateOrTime(date: Date, showSeconds = false, locale?: string): string {
    if (this.datesAreOnSameDay(date, this.now)) {
      return this.formatTime(date, showSeconds, locale);
    }
    return this.formatDate(date, locale);
  }

  static formatDateForStorage(date: Date): string {
    return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
  }

  static get now(): Date {
    return new Date();
  }

  static resetTimeComponent(date: Date): Date {
    const clone = new Date(date.getTime());
    clone.setHours(0, 0, 0, 0);
    return clone;
  }

  static get todayStart(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  static get todayEnd(): Date {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today;
  }

  static get minDate(): Date {
    return new Date(1700, 1, 1);
  }

  static addDays(days: number, date?: Date): Date {
    const result = date || this.todayStart;
    result.setDate(result.getDate() + days);
    return result;
  }

  static addWeeks(weeks: number, date?: Date): Date {
    const result = date || this.todayStart;
    result.setDate(result.getDate() + weeks * 7);
    return result;
  }

  static addMonths(months: number, date?: Date): Date {
    const result = date || this.todayStart;
    return new Date(result.setMonth(result.getMonth() + months));
  }

  static addYears(years: number, date?: Date): Date {
    const result = date || this.todayStart;
    return new Date(result.setFullYear(result.getFullYear() + years));
  }

  static setDate(amount: number, interval: DateInterval): Date {
    switch (interval) {
      case DateInterval.DAY: {
        return this.addDays(amount);
      }
      case DateInterval.WEEK: {
        return this.addWeeks(amount);
      }
      case DateInterval.MONTH: {
        return this.addMonths(amount);
      }
      case DateInterval.YEAR: {
        return this.addYears(amount);
      }
    }
  }

  static get weekdays() {
    return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const;
  }

  public static getMonthTranslation(t: TFunction<Namespace>, month: number, short = false): string {
    const length = short ? 'short' : 'long';
    const monthKey = this.months[month - 1];
    return t(`common:month.${monthKey}.${length}`);
  }

  static isTimeExpired = (unix: number | null): boolean => (unix ? unix < Date.now() : true);

  public static formatSeconds(seconds: number): string {
    return `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`;
  }

  public static getPartOfDay(t: TFunction<Namespace>) {
    const today = new Date();
    const currentHour = today.getHours();
    if (currentHour < 12) {
      return t('common:greeting.morning');
    } else if (currentHour < 18) {
      return t('common:greeting.afternoon');
    } else {
      return t('common:greeting.evening');
    }
  }

  public static datesAreOnSameDay = (first: Date, second: Date) => {
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
  };

  public static overDueText = (date: Date, t: TFunction<['common']>) => {
    if (this.datesAreOnSameDay(date, this.now)) {
      return t('common:overdue.today');
    } else if (date < this.now) {
      return t('common:overdue.days', { days: this.getDaysBetween(this.now, date) });
    }

    return this.formatDate(date);
  };

  public static isValidDate(date: Date) {
    return date instanceof Date && !isNaN(date.getTime());
  }

  public static maxDateTime(dates: string[]) {
    return this.formatDateTime(
      new Date(
        Math.max.apply(
          null,
          dates.map((date) => new Date(date).getTime()),
        ),
      ),
    );
  }

  public static dateIntervalOptions(t: TFunction<Namespace>) {
    return [
      { id: DateInterval.DAY.toString(), value: DateInterval.DAY, text: t(DateIntervalKeys[DateInterval.DAY]) },
      { id: DateInterval.WEEK.toString(), value: DateInterval.WEEK, text: t(DateIntervalKeys[DateInterval.WEEK]) },
      { id: DateInterval.MONTH.toString(), value: DateInterval.MONTH, text: t(DateIntervalKeys[DateInterval.MONTH]) },
      { id: DateInterval.YEAR.toString(), value: DateInterval.YEAR, text: t(DateIntervalKeys[DateInterval.YEAR]) },
    ];
  }
}

export default DateUtils;
