import themes from '../themes';
import { simpleGlobalState } from './SimpleGlobalState';

const DEFAULT_THEME_NAME = 'playbook';

const currentTheme = import.meta.env.VITE_THEME
  ? themes[(import.meta.env.VITE_THEME as string).trim().toLowerCase() || DEFAULT_THEME_NAME]
  : themes[DEFAULT_THEME_NAME]; // white label approach

interface Font {
  sans: string;
  signature: string;
}

interface Image {
  logo: string;
  logoFullDark: string;
  authBg: string;
}

export interface ThemeStateProps {
  name: string;
  hasSubHeading: boolean;
  styles: {
    color: Record<string, Record<string, string | unknown>>;
    font: Font;
  };
  assets: {
    image: Image;
    fonts: Record<string, string>;
  };
}

export const useTheme = simpleGlobalState<ThemeStateProps | null>(currentTheme || null);
