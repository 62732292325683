import { FC } from 'react';
import { Route, Navigate, Routes as RouterRoutes } from 'react-router-dom';
import ErrorPage from './components/error/ErrorPage';
import ErrorType from './models/Error';
import ProtectedRouteWithTitle from './ProtectedRouteWithTitle';
import PublicRouteWithTitle from './PublicRouteWithTitle';
import { useErrors } from './global-state/Errors';
import Routes from './routes';
import { usePbkAuth } from './contexts/PbkAuthContext';

const { NOT_FOUND } = ErrorType;

const Router: FC = () => {
  const { isAuthenticated } = usePbkAuth();
  const errorState = useErrors((x) => x.value);

  return errorState ? (
    <ErrorPage {...errorState} />
  ) : (
    <RouterRoutes>
      {Routes.map((route) => {
        const paths = Array.isArray(route.path) ? route.path : [route.path];

        return paths.map((path) =>
          route.publicPage ? (
            <Route key={route.id} path={path} element={<PublicRouteWithTitle key={route.id} {...route} />} />
          ) : (
            <Route key={route.id} path={path} element={<ProtectedRouteWithTitle key={route.id} {...route} />} />
          ),
        );
      })}

      <>
        <Route
          index
          element={
            <ProtectedRouteWithTitle key="index" translationKey="page.clients-list" id="root" path="/" component={() => <Navigate to="/clients" />} />
          }
        />
        <Route path="*" element={<ErrorPage key="404" type={NOT_FOUND} showGoBack showContactSupport={isAuthenticated} />} />
      </>
    </RouterRoutes>
  );
};

export default Router;
