import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../contexts/ToastContext';
import { EventSystem } from '../events/EventSystem';
import ErrorType from '../models/Error';
import { useErrors } from '../global-state/Errors';
import BaseService from '../services/BaseService';
import ObjectUtils from '../utils/ObjectUtils';
import useRefreshToken from './useRefreshToken';
import useSentry from './useSentry';
import { usePbkAuth } from '../contexts/PbkAuthContext';
import AuthService from '../services/AuthService';
import { useCurrentClient } from '../global-state/Clients';
import { useCurrentRouteState } from '../global-state/Layout';

const { UNAUTHORIZED, FORBIDDEN, SERVER_ERROR, SERVICE_UNAVAILABLE, UNKNOWN_ERROR } = ErrorType;

const useErrorInterceptor = () => {
  const { captureApiError } = useSentry();
  const { t } = useTranslation('common');
  const toastsRef = useRef(useToasts());
  const setErrorState = useErrors((x) => x.setValue);
  const refreshToken = useRefreshToken();
  const currentClient = useCurrentClient((x) => x.value);
  const setError = useCallback(
    (type: (typeof ErrorType)[keyof typeof ErrorType]) => {
      setErrorState({ type });
    },
    [setErrorState],
  );
  const [httpClientChanged, setHttpClientChanged] = useState({}); // dummy value
  const auth = usePbkAuth();
  const currentRoute = useCurrentRouteState((x) => x.value);

  useEffect(() => {
    const handler = () => {
      setHttpClientChanged({}); //mark as changed
    };
    EventSystem.listen('http-client-recreated', handler);

    return () => {
      EventSystem.stopListening('http-client-recreated', handler);
    };
  }, []);

  useEffect(
    function errorInterceptor() {
      /**
       * If the response contains data, trim it so that we protect sensitive information.
       * @param res The axios response
       */

      const trimData = (res: AxiosResponse) => {
        const clone = ObjectUtils.DeepClone(res);
        if (clone.data.data) delete clone.data.data;
        return clone;
      };

      const responseInterceptor = BaseService.httpClient.interceptors.response.use(
        async (res) => {
          if (
            !BaseService.disableInterceptorErrors &&
            [400, 401, 403, 500].filter((status) => !res.config.ignoredErrorStatusCodes?.includes(status)).includes(res.status)
          ) {
            const prevRequest = res?.config;

            if (res.status === 400) {
              toastsRef.current.addToast({
                title: t('errors.bad-request.subtitle'),
                type: ToastType.ERROR,
                description: res.data.meta ? res.data.meta.message : t('errors.bad-request.description'),
                expiresInMs: 10000,
              });
            } else if (!prevRequest.isTokenRefresh && !currentRoute?.publicPage) {
              if ([401, 403].includes(res.status) && !prevRequest?.sent) {
                if (auth.features.unmanagedTokens) {
                  if (
                    res.status === 401
                      ? await refreshToken(true).then(() => AuthService.switchAccounts(currentClient?.id, true))
                      : await AuthService.switchAccounts(currentClient?.id, true)
                  ) {
                    return BaseService.httpClient({ ...prevRequest, sent: true });
                  } else {
                    setError(res.status === 401 ? UNAUTHORIZED : FORBIDDEN);
                  }
                } else {
                  setError(res.status === 401 ? UNAUTHORIZED : FORBIDDEN);
                }
              } else if (res.status === 401 && prevRequest?.sent) {
                setError(UNAUTHORIZED);
              } else if (res.status === 403 && prevRequest?.sent) {
                setError(FORBIDDEN);
              } else {
                setError(SERVER_ERROR);
              }
            }
          }

          if (res.status === 0 || (res.status >= 400 && res.status !== 404)) {
            // Log to sentry
            if (res.status === 0 || res.status >= 500) {
              captureApiError({
                response: trimData(res),
              });
            }

            throw res;
          }

          return res;
        },
        (err) => {
          if (err.code === 'ERR_CANCELED') {
            return Promise.reject('Request was canceled');
          }

          captureApiError({
            error: JSON.stringify(err),
          });

          if (err.response === undefined || err.response.status === 0) {
            setError(SERVICE_UNAVAILABLE);
          } else {
            setError(UNKNOWN_ERROR);
          }

          return Promise.reject(err);
        },
      );

      return () => {
        BaseService.httpClient.interceptors.response.eject(responseInterceptor);
      };
    },
    // httpClientChanged SHOULD be in here
    [captureApiError, refreshToken, setError, t, httpClientChanged, auth.features.unmanagedTokens, currentClient?.id, currentRoute?.publicPage],
  );
};

export default useErrorInterceptor;
